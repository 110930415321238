import React from 'react';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import FavoriteButton from './FavoriteButton';
import { FARM_ROUTE } from '../constants';
import FarmInfoTable from './FarmInfoTable';

export default ({ bannerImage, profileImage, name, location, about, availableProducts, farmID, addSnackbarMessage, path, organicStatus }) => {
    return (
        <>
            <div className='paper'>
                <div className='banner-img'>
                    <GatsbyImage fluid={bannerImage.childImageSharp.fluid} style={{height: '100%'}} />
                </div>
                <div className='farm-padding'>
                    <div className='farm-heading'>
                        <div className='img-container'>
                            <GatsbyImage fluid={profileImage.childImageSharp.fluid} />
                        </div>
                        <div className='farm-info'>
                            <h3>{name}</h3>
                            <FarmInfoTable availableProducts={availableProducts} location={location} organicStatus={organicStatus} />
                        </div>
                        <div className='btn-container'>
                            <FavoriteButton itemID={farmID} favoriteType="FARMS" addSnackbarMessage={addSnackbarMessage} redirectPath={`${FARM_ROUTE}${path}`} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='paper farm-padding'>
                <h5>About</h5>
                <div dangerouslySetInnerHTML={{__html: about}} />
            </div>
        </>
    )
}