import React from 'react';
import Icon from './Icon';

const organicStatuses = {
    "CERTIFIED_ORGANIC": {
        icon: "checkCircle",
        text: "Certified organic"
    },
    "ORGANIC": {
        icon: "checkOutline",
        text: "Organic" 
    }
}

export default ({ location, organicStatus, availableProducts }) => {
    return (
        <table className='farm-info-table'>
            <tbody>
                <tr>
                    <td className='text-secondary-dark'><Icon width={20} iconName='mapMarker'/></td>
                    <td><p>{location}</p></td>
                </tr>
                {organicStatuses.hasOwnProperty(organicStatus) && <tr>
                    <td className='text-primary'><Icon width={20} iconName={organicStatuses[organicStatus].icon}/></td>
                    <td><p>{organicStatuses[organicStatus].text}</p></td>
                </tr>}
                <tr>
                    <td className='text-teal'><Icon width={20} iconName='seedling'/></td>
                    <td><p>{availableProducts} product{availableProducts === 1 ? "" : "s"} currently available</p></td>
                </tr>
            </tbody>
        </table>
    )
}