import React from 'react';
import { graphql } from 'gatsby';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api'
import Layout from '../components/Layout';
import FarmContainer from '../components/FarmContainer';
import { FARM_ROUTE, GOOGLE_MAPS_SCRIPT_ID } from '../constants';
import { GOOGLE_MAPS_API_KEY } from '../config';
import FarmProductList from '../components/FarmProductList';

const libraries = ["places"];
const FarmPageContainer = ({ farmID, products, farm, addSnackbarMessage }) => {
  const { isLoaded } = useLoadScript({
      googleMapsApiKey: GOOGLE_MAPS_API_KEY,
      libraries,
      id: GOOGLE_MAPS_SCRIPT_ID
  })
  const position = {
    lat: farm.lat,
    lng: farm.lng
  }
  return (
    <div className='grey-bg'>
      <div className='farm-layout-container'>
        <div className='grid-container farm-container'>
          <div className='grid-item-2-3 farm-item'>
            <FarmContainer {...farm} availableProducts={products.length} farmID={farmID} addSnackbarMessage={addSnackbarMessage} />
            {products.length > 0 &&
              <div className='paper farm-padding'>
                <h5>Available products</h5>
                <FarmProductList products={products} />
              </div>
            }
          </div>
          <div className='grid-item-3 farm-item right-farm-item'>
            {isLoaded && 
            <>
              <div className='paper google-map-container'>
                <GoogleMap
                  mapContainerStyle={{
                    height: "100%",
                    width: "100%"
                  }}
                  zoom={14}
                  center={position}
                  options={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    mapTypeId: 'hybrid'
                }}
                >
                  <Marker
                    position={position}
                  />
                </GoogleMap>
              </div>
            </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ({ data: { farm, allProduct: { edges: products } }, pageContext }) => {
  return (
    <Layout pageTitle={farm.name} addLayoutProps ogUrl={`${FARM_ROUTE}${farm.path}/`}>
      <FarmPageContainer farm={farm} products={products} farmID={pageContext.id} />
    </Layout>
  )
};

export const query = graphql`
  query($id: String!) {
    farm(id: {eq: $id}) {
      name
      location
      about
      lat
      lng
      path
      organicStatus
      bannerImage {
        childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
        }
      }
      profileImage {
        childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
        }
      }
    }
    allProduct(filter: {status: {eq: "ACTIVE"}, farmID: {eq: $id}}) {
      edges {
        node {
          displayPrice
          about
          name
          path
          productImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            } 
          }
        }
      }
    }
  }
`